import { useStore } from "../Helpers"
import { useSelector } from 'react-redux';

const baseUrl = `${window.location.protocol}//${window.location.hostname}`

export const useApi = () => {
	const token = useSelector(({ user }) => user.CSRFToken);
	const { set } = useStore();

	const loadCSRFToken = () => {
		return fetch(`${baseUrl}/session/token`)
			.then(res => res.text())
			.then(freshToken => set('user:CSRFToken', freshToken))
	}

	const throwError = (message, cause) => {
		throw new Error(message, { cause: cause })
	}

	const fetchApi = async (uri, meth, csrf = false, data = false) => {
		return fetch(`${baseUrl}/api/${uri}`, {
			method: meth,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				...csrf && { 'X-CSRF-Token': token || await loadCSRFToken() }
			},
			...data && { body: JSON.stringify(data) },
		})
			.then(async (res) => [res, await res.json()])
			.then(([res, body]) => res.ok ? body : throwError(body.message || body, res))
	}

	return {
		get: async (uri, useCSRF = false) => await fetchApi(uri, 'GET', useCSRF),
		post: async (uri, data, useCSRF = false) => await fetchApi(uri, 'POST', useCSRF, data),
		patch: async (uri, data, useCSRF = false) => await fetchApi(uri, 'PATCH', useCSRF, data),
		remove: async (uri, useCSRF = false) => await fetchApi(uri, 'DELETE', useCSRF),
	}
}