import { createStore } from 'redux'
import { fromJS } from 'immutable'
import { md5 } from './Helpers'

const initialState = {
  user: {uid: false},
  isMember: null,
  menu: {account: false, main: false, sub: false},
  appbar: {title: 'Loading...'},
  showLoader: false,
  loading: false,
  editor: {
    prompt: false,
    token: false,
  },
  dromptEditor: {
    position: {key: 0,start: 0,end: 0},
    content: false
  },
  shablons: {},
  shablonsLoaded: {},
  shablonResults: {},

  entities: {
    shablons: {}
  }
};

const reducer = (state = initialState, action) => {

  if (!action.path || !action.path.length) {
    return state
  }

  // console.log(action.type, action.path,  action.payload);


  switch (action.type) {
    case 'remove':
      return { ...state, ...(fromJS(state).deleteIn(action.path).toJS()) }
    case 'add':
      return { ...state, ...(fromJS(state).mergeIn(action.path, fromJS(action.payload)).toJS()) }
    case 'set':
      return { ...state, ...(fromJS(state).setIn(action.path, fromJS(action.payload)).toJS()) }
  }

  // if (md5(oldValue) !== md5(newValue)) {
  //   obj = obj.setIn(['cache', path.join('.')], md5(value))
  // }
  
  return state
};

const store = createStore(reducer);

export default store;