import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { useApi } from "./Helpers/Api"
import CryptoJS from 'crypto-js';
import { useState, useEffect } from 'react';

export function md5(obj) {
	return CryptoJS.MD5(JSON.stringify(obj)).toString();
}

export function uuid(prefix = false) {
	return (prefix || null) + md5(Math.random()).substring(0, 3);
}

export const useStore = () => {
	const dispatch = useDispatch();
	return {
		set: (path, value, keepOld = false) => {
			dispatch({ type: keepOld ? 'add' : 'set', path: (typeof path === 'string') ? path.split(':') : path, payload: value });
			return value
		},
		add: (path, value) => {
			dispatch({ type: 'add', path: path.split(':'), payload: value });
			return value
		},
		remove: (path) => {
			dispatch({ type: 'remove', path: path.split(':') });
		},
	}
}


export function findEntityByAlias(view, alias, items = {}) {
	let item = false;
	Object.entries(items).forEach(([, i]) => {
		if (i.uri === alias && i.view === view) {
			item = i
		}
	});

	return item;
}
export function findEntityById(id, items = {}) {
	let item = false;
	Object.entries(items).forEach(([, i]) => {
		if(i){
			console.log(i.id, id)
			if (i.id === id) {
				item = i
			}
		}

	});

	return item;
}
export function findEntitiesById(ids, items = {}) {
	let entities = [];
	Object.entries(items).forEach(([, item]) => {
		if (ids.includes(item.id)) {
			entities.push(item)
		}
	});

	return entities;
}

export const useLoadMenus = () => {
	const { set } = useStore();
	const { get } = useApi();

	return async () => get(`menus/main,account,sub`).then(menus => set('menu', menus))
}

export const useLoadNode = () => {
	const { set } = useStore();
	const { get } = useApi();
	const navigate = useNavigate();

	return async (alias) => get(`content?alias=${alias}`, true)
		.then(node => set('nodes:' + +node.nid, node))
		.catch(err => {
			switch (err.cause.status) {
				case 404: navigate("/404", { replace: true }); break;
				default: navigate("/403", { replace: true }); break;
			}
		})
}

const entityTypes = {
	content: {
		url: ({alias}) => `content?alias=${alias}`,
		storePath: ({alias}) => `nodes:${alias}`,
		selector: (entities, props) => entities?.nodes?.[props.alias],
		maxLifetime: 3600,
	},	
	shablon: {
		url: ({id}) => `shablon/${id}`,
		storePath: ({id}) => `shablons:${id}`,
		selector: (entities, props) => entities?.shablons?.[props.id],
		maxLifetime: 3600,
		validateRespone: (response, props) => {
			return true
		},
	},
	shablonResult: {
		url: ({id, submit}) => `shablon-result/${id}${submit ? '/submit' : ''}`,
		storePath: ({id}) => `shablonResults:${id}`,
		selector: (entities, props) => entities?.shablonResults?.[props.id],
		validateRespone: (shablonResult, props) => {

			if (shablonResult.submitted && !shablonResult.succeed) {
				console.log("shablonResult.submitted && !shablonResult.succeed");
				return true
			}


			return true
		},
		maxLifetime: 999990,
	},
}

export const useEntityLoader = (entityTypeId, props) => {
	const { get } = useApi();
	const { set, add } = useStore();

	const entityType = entityTypes[entityTypeId]
	const entityApiUrl = entityType.url(props)
	const entityStorePath = entityType.storePath(props)

	const entity = useSelector(({ entities }) => {
		const entityCache = entityType.selector(entities, props)
		// console.log('entityCache', entityCache, entityApiUrl)
		if (!entityCache) {
			return false;
		} 

		else if (entityCache?.status) {
			switch (entityCache.status) {
				case 'loading':
					// console.log('loading', Date.now() - entityCache.loadingStarted, entityCache)
					break;
				case 'success':
					const lastTimeLoaded = Math.floor((Date.now() - entityCache.loadingStarted) / 1000)
					const maxLifetime = props.resetCache ? 0 : entityType.maxLifetime
					if (lastTimeLoaded > maxLifetime) {
						console.info('Cache maxLifetime reached!', lastTimeLoaded)
						return {
							...entityCache,
							needRebuild: true
						};
					}
					break;
			}
		}
	
		return entityCache
	})
	
	useEffect(() => { 
		if(entity === false) {
			// console.log('loading', entityTypeId, entityApiUrl)
			const tempEntity = {
				status: 'loading',
				loadingStarted: Date.now()
			}
	
			add('entities:' + entityStorePath, tempEntity) 

			get(entityApiUrl, true).then((loadedEntity) => {
				console.log('--loading ready', loadedEntity, entity)
 
				if(entityType.validateRespone(loadedEntity, props)){
					set('entities:' + entityStorePath, {
						...tempEntity,
						...loadedEntity,
						status: 'success',
						loadingSuccessed: Date.now()
					})
				} else {
					console.log('not valid', loadedEntity, props);
				}

			})
		}
	}, [entity])

	return entity;
}

export const useEntityManager = () => ({
	load: useEntityLoader
})

// export const useLoadConcepts = () => {
// 	const { add } = useStore();
// 	const navigate = useNavigate();

// 	return async () => fetchJson(`concept/all`, true)
// 		.then(concepts => {
// 			add('concepts', concepts)
// 			add('conceptsLoaded', true)
// 		})
// 		.catch(err => {
// 			switch (err.cause.status) {
// 				case 404: navigate("/404", { replace: true }); break;
// 				default: navigate("/403", { replace: true }); break;
// 			}
// 		})
// }
// export const useAddWishlist = () => {
// 	const { add, set } = useStore();
// 	const navigate = useNavigate();
// 	const { post } = useApi();
// 	return async (product) => {
// 		await post(`wishlist/add`, true, { 'product': product.id })
// 			.then(wishlistItem => {
// 				set(`products:${product.id}:wishlist`, wishlistItem)
// 				set(`wishlist:${product.id}`, wishlistItem)
// 			})
// 		// .catch(err => console.log(err))
// 	}
// }
// export const useRemoveWishlist = () => {
// 	const { set } = useStore();
// 	const { remove } = useApi();
// 	return async (product) => {
// 		await remove(`wishlist/${product.id}`, true)
// 			.then(() => {
// 				// set(`wishlist:${product.id}`, false)
// 				set(`products:${product.id}:wishlist`, false)
// 			})
// 		// .catch(err => console.log(err))
// 	}
// }

