import { useStore } from "../Helpers"
import { useApi } from "./Api"

export default () => {
	const { set } = useStore()
	const { get, post } = useApi()

	return {
		load:  async () => {
			const loadUserAsync = async () => {
				return await get(`user/data`).then(user => set('user', user))
			}
			const user = await loadUserAsync()
			// console.log("+++++user", user);
			return user
		},
		login: async (v) => await post(`user/login`, v).then(user => set('user', user)),
		logout: async () => await get(`user/logout`).then(user => set('user', user)),

	}

	return async () => await get(`user/data`).then(user => set('user', user))
}

export const useLoadUser = () => {
	const { set } = useStore()
	const { get } = useApi()
	return async () => await get(`user/data`).then(user => set('user', user))
}

export const useLogin = () => {
	const { set } = useStore()
	const { post } = useApi()
	return async (v) => await post(`user/login`, v).then(user => set('user', user))
}

export const useLogout = () => {
	const { set } = useStore()
	const { get } = useApi()
	return async () => await get(`user/logout`).then(user => set('user', user))
}

export const usePachUser = () => {
	const { set } = useStore()
	const { patch } = useApi()
	return async (v) => await patch(`user/data`, v, true).then(user => set('user', user))
}

export const useRegisterUser = () => {
	const { set } = useStore()
	const { post } = useApi()
	return async (v) => await post(`user/register`, v).then(user => set('user', user))
}

export const useResetPassword = () => {
	const { post } = useApi()
	return async (v) => await post(`user/reset`, v)
}