import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useStore } from './Helpers'
import useUser from "./Helpers/User"
 
import { useSelector } from 'react-redux';
import LoadingSpinner from './Components/LoadingSpinner'
// import './Main.scss';
// import './Styles/Font.scss';
import './Styles/Main.scss';
import Sidebar from './partials/Sidebar';
import Header from './partials/Header';

const UserLoader = () => {
  const { set } = useStore();
  const { load } = useUser();
  useEffect(() => {
      load()
        .then((user) => set('isMember', user.uid > 0))
        .then(() => document.querySelector('html').classList.add('loaded'))
  }, [])
  return <></>
}

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

 
  const Error404 = lazy(() => import('./Screens/Errors/Error404'));

  const Signin = lazy(() => import('./pages/Signin'));
  // const Account = lazy(() => import('./Screens/User/Account'));

  // const DromptPage = lazy(() => import('./Screens/Drompt/DromptPage'));
 
  const Shablons = lazy(() => import('./pages/Shablons'));
  const ShablonAdd = lazy(() => import('./pages/ShablonAdd'));
  const Shablon = lazy(() => import('./pages/Shablon'));

  
  const ShablonEdit = lazy(() => import('./pages/ShablonEdit'));

  const isMember = useSelector(({ isMember }) => isMember)

  if (isMember === null) {
    return <UserLoader />;
  }

  if (isMember === false) {
    return (
      <>
        {/* <LoadingScreen />  */}
        <Suspense fallback={<LoadingScreenActivtor />}>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Suspense>
      </>
    );
  }



  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* <LoadingScreen />  */}
        <Suspense fallback={<LoadingScreenActivtor />}>
          <Routes>
            <Route path="/" element={<Shablons />} />
            <Route path="/shablons" element={<Shablons />} />
            <Route path="/shablon/add" element={<ShablonAdd />} />
            
            <Route path="/shablon/:id" element={<Shablon />} />
            <Route path="/shablon/:id/edit" element={<ShablonEdit />} />
            {/* <Route path="/add-drompt" element={<DromptAdd />} /> */}
            {/* <Route path="/user" element={<Account />} /> */}
            {/* <Route path="/user/register" element={<Register />} /> */}
            {/* <Route path="/user/reset" element={<ResetPasswort />} /> */}
            <Route path="/login" element={<Signin />} />
            {/* <Route path="/user/logout" element={<Logout />} /> */}
            {/* <Route path="/403" element={<Error403 />} /> */}
            {/* <Route path="/404" element={<Error404 />} /> */}
            {/* <Route path="/test" element={<Shablons />} /> */}
            {/* <Route path="/*" element={<NodePage />} /> */}
            <Route path="*" element={<Error404 />} />

          </Routes>

        </Suspense>

      </div>
    </div>
  );
}




const LoadingScreen = () => {
  // const showLoader = useSelector(({showLoader}) => showLoader);
  return (
    <div id='loadingScreen' className={`bg-gray-main inset-0 flex flex-col justify-center items-center absolute z-40 transition-all ease-in `}>
      <div className='mb-5 text-gray-light'>Loading... </div>
      <LoadingSpinner style={'light'} />
    </div>
  );
}

const LoadingScreenActivtor = () => {
  // useEffect(() => {
  //   console.log('LoadingScreenActivtor')
  //     document.querySelector('html').classList.add('loading')
  //     return (() => document.querySelector('html').classList.remove('loading'))
  // }, [] )
  return <></>
}

export default App;